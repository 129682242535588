import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/marketing/mailinglist`

const loadMailingLists = () => {
  return axios.get(api, {})
}

export default {
  loadMailingLists
}
