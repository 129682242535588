import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/marketing/promotion`

const loadPromotions = () => {
  return axios.get(api, {})
}
const loadPromotion = (id) => {
  return axios.get(api + '/' + id)
}
const createPromotion = (promotion) => {
  return axios.post(api, { ...promotion })
}
const updatePromotion = (promotion) => {
  return axios.put(api, { ...promotion })
}
const emailPromotion = (promotion) => {
  return axios.post(api + '/email', { ...promotion })
}

export default {
  loadPromotions,
  loadPromotion,
  createPromotion,
  updatePromotion,
  emailPromotion
}
